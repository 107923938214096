<template>
  <v-container>
    <TheTitle text="Classificação de Riscos" icon="mdi-chart-box-outline">
    </TheTitle>
    <v-row>
      <v-col cols="6">
        <v-card class="pa-5 fill-height">
          <h1 class="mb-5">1. Classificação de Risco</h1>
          <v-simple-table width="100%">
            <thead>
              <tr>
                <td width="60%"><b>Dados para gráfico</b></td>
                <td width="20%" class="text-right"><b>Ocorrências</b></td>
                <td width="20%"></td>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>a) Risco Alto</td>
                <td class="text-right">{{ risks.low.total }}</td>
                <td class="text-right">{{ risks.low.percent.toFixed(2) }} %</td>
              </tr>
              <tr>
                <td>b) Risco Médio</td>
                <td class="text-right">{{ risks.medium.total }}</td>
                <td class="text-right">
                  {{ risks.medium.percent.toFixed(2) }} %
                </td>
              </tr>
              <tr>
                <td>c) Risco Baixo</td>
                <td class="text-right">{{ risks.high.total }}</td>
                <td class="text-right">
                  {{ risks.high.percent.toFixed(2) }} %
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="pa-5 fill-height">
          <h3 class="mb-5">1.1. Ocorrências</h3>
          <v-chart class="chart" :option="option" />
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <v-card class="pa-5 fill-height">
          <h1 class="my-5">2. Setor x Riscos</h1>
          <v-row class="mb-2">
            <v-col><h3>Setor</h3></v-col>
            <v-col class="text-right"><h3>Ocorrência</h3></v-col>
          </v-row>

          <div v-for="(item, i) in risksList" :key="i">
            <div
              v-if="
                item.risks.high.total > 0 ||
                  item.risks.medium.total > 0 ||
                  item.risks.low.total > 0
              "
              class="mb-5"
            >
              <h4>{{ item.name }}</h4>
              <v-simple-table width="100%">
                <tbody>
                  <tr class="pa-0 ma-0 ml-5" v-if="item.risks.high.total > 0">
                    <td class="py-0 px-2 ma-0">Risco Alto</td>
                    <td class="py-0 px-2 ma-0 text-right">
                      {{ item.risks.high.total }}
                    </td>
                  </tr>
                  <tr class="pa-0 ma-0 ml-5" v-if="item.risks.medium.total > 0">
                    <td class="py-0 px-2 ma-0">Risco Médio</td>
                    <td class="py-0 px-2 ma-0 text-right">
                      {{ item.risks.medium.total }}
                    </td>
                  </tr>
                  <tr class="pa-0 ma-0 ml-5" v-if="item.risks.low.total > 0">
                    <td class="py-0 px-2 ma-0">Risco Baixo</td>
                    <td class="py-0 px-2 ma-0 text-right">
                      {{ item.risks.low.total }}
                    </td>
                  </tr>
                </tbody>
              </v-simple-table>
            </div>
          </div>
        </v-card>
      </v-col>
      <v-col>
        <v-card class="pa-5 fill-height">
          <h1 class="my-5">3. Riscos x Setor</h1>

          <h3 class="my-5">Risco Alto</h3>
          <div>
            <v-simple-table width="100%">
              <tbody>
                <tr
                  v-for="(item, i) in risksList"
                  :key="i"
                  class="pa-0 ma-0 ml-5"
                >
                  <td class="py-0 px-2 ma-0" v-if="item.risks.high.total > 0">
                    {{ item.name }}
                  </td>
                  <td
                    class="py-0 px-2 ma-0 text-right"
                    v-if="item.risks.high.total > 0"
                  >
                    {{ item.risks.high.total }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>

          <h3 class="my-5">Risco Médio</h3>
          <div>
            <v-simple-table width="100%">
              <tbody>
                <tr
                  v-for="(item, i) in risksList"
                  :key="i"
                  class="pa-0 ma-0 ml-5"
                >
                  <td class="py-0 px-2" v-if="item.risks.medium.total > 0">
                    {{ item.name }}
                  </td>
                  <td
                    class="py-0 px-2 text-right"
                    v-if="item.risks.medium.total > 0"
                  >
                    {{ item.risks.medium.total }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>

          <h3 class="my-5">Risco Baixo</h3>
          <div>
            <v-simple-table width="100%">
              <tbody>
                <tr
                  v-for="(item, i) in risksList"
                  :key="i"
                  class="pa-0 ma-0 ml-5"
                >
                  <td class="py-0 px-2" v-if="item.risks.low.total > 0">
                    {{ item.name }}
                  </td>
                  <td
                    class="py-0 px-2 text-right"
                    v-if="item.risks.low.total > 0"
                  >
                    {{ item.risks.low.total }}
                  </td>
                </tr>
              </tbody>
            </v-simple-table>
          </div>
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import axios from 'axios'
import { baseApiUrl, showError } from '@/global'
import TheTitle from '@/components/TheTitle'

import 'echarts'
import VChart from 'vue-echarts'

export default {
  computed: {},
  components: {
    TheTitle,
    VChart,
  },
  data: () => ({
    objects: [],
    legal: [
      { text: 'Consentimento do titular', value: '1' },
      { text: 'Cumprimento de obrigação legal ou regulatória', value: '2' },
      {
        text: 'Execução, pela administração pública, de políticas públicas',
        value: '3',
      },
      { text: 'Realização de estudos por órgão de pesquisa', value: '4' },
      { text: 'Execução de contrato', value: '5' },
      { text: 'Diligências pré-contratuais', value: '6' },
      { text: 'Exercício regular de direitos', value: '7' },
      { text: 'Proteção da vida e da incolumidade física', value: '8' },
      { text: 'Tutela da saúde', value: '9' },
      { text: 'Legítimo Interesse', value: '10' },
      { text: 'Proteção de crédito', value: '11' },
      { text: 'Prevenção à fraude em sistemas eletrônicos', value: '12' },
      { text: 'Segurança do titular em sistemas eletrônicos', value: '13' },
      { text: 'Não atendida', value: '14' },
    ],
    type: [
      { text: 'Coleta', value: '1' },
      { text: 'Uso/Finalidade', value: '2' },
      { text: 'Compartilhamento', value: '3' },
      { text: 'Retenção/Armazenamento', value: '4' },
      { text: 'Descarte', value: '5' },
    ],
    risks: {
      low: {
        total: 0,
        percent: 0,
      },
      medium: {
        total: 0,
        percent: 0,
      },
      high: {
        total: 0,
        percent: 0,
      },
      count: 0,
    },
    probability: [
      { text: 'Quase certo', value: 1 },
      { text: 'Alta', value: 0.8 },
      { text: 'Média', value: 0.6 },
      { text: 'Baixa', value: 0.4 },
      { text: 'Rara', value: 0.2 },
    ],
    impact: [
      { text: 'Gravíssimo', value: 0.8 },
      { text: 'Grave', value: 0.6 },
      { text: 'Médio', value: 0.4 },
      { text: 'Leve', value: 0.2 },
      { text: 'Sem Impacto', value: 0.05 },
    ],
    option: {
      tooltip: {
        trigger: 'item',
        formatter: '{b}: {c}%'
      },
      legend: {
        bottom: '5%',
        right: '0',
      },
      series: [
        {
          name: 'Classificação do risco',
          type: 'pie',
          radius: ['40%', '70%'],
          avoidLabelOverlap: false,
          itemStyle: {
            borderRadius: 10,
            borderColor: '#fff',
            borderWidth: 2,
          },
          label: {
            show: false,
            position: 'center',
          },
          emphasis: {
            label: {
              show: true,
              fontSize: '40',
              fontWeight: 'bold',
            },
          },
          labelLine: {
            show: false,
          },
          data: [],
        },
      ],
    },
    risksList: [],
    sectorList: [],
  }),
  methods: {
    loadData() {
      const url = `${baseApiUrl}/report/risk`
      this.$store.dispatch('setLoadingInfo', true)
      axios
        .get(url)
        .then((res) => {
          this.objects = res.data
          this.getRiscos()
          this.$store.dispatch('setLoadingInfo', false)
        })
        .catch(showError)
    },
    loadRisksBySector() {
      const url = `${baseApiUrl}/report/risk/sector`
      axios
        .get(url)
        .then((res) => {
          this.risksList = res.data
        })
        .catch(showError)
    },
    getType(id) {
      for (let i = 0; i < this.type.length; i++) {
        const element = this.type[i]
        if (element.value === id) {
          return element.text
        }
      }
    },
    getLegal(id) {
      for (let i = 0; i < this.legal.length; i++) {
        const element = this.legal[i]
        if (element.value === id) {
          return element.text
        }
      }
    },
    getRiscos() {
      for (let i = 0; i < this.objects.length; i++) {
        const element = this.objects[i]
        if (this.getRisk(element.risk) === 'Risco Baixo') {
          this.risks.low.total++
        }
        if (this.getRisk(element.risk) === 'Risco Médio') {
          this.risks.medium.total++
        }
        if (this.getRisk(element.risk) === 'Risco Alto') {
          this.risks.high.total++
        }
      }
      if (this.objects.length > 0) {
        this.risks.low.percent =
          (this.risks.low.total * 100) / this.objects.length
        this.risks.medium.percent =
          (this.risks.medium.total * 100) / this.objects.length
        this.risks.high.percent =
          (this.risks.high.total * 100) / this.objects.length
      }

      this.option.series[0].data = [
        {
          value: this.risks.low.percent.toFixed(2),
          name: 'Baixo',
          itemStyle: { color: '#689F38' },
        },
        {
          value: this.risks.medium.percent.toFixed(2),
          name: 'Médio',
          itemStyle: { color: '#FDD835' },
        },
        {
          value: this.risks.high.percent.toFixed(2),
          name: 'Alto',
          itemStyle: { color: '#880E4F' },
        },
      ]
    },
    getRisk(risk) {
      if (!risk) {
        return ''
      }
      if (risk <= 0.12) {
        return 'Risco Baixo'
      }
      if (risk > 0.12 && risk <= 0.32) {
        return 'Risco Médio'
      }
      return 'Risco Alto'
    },
    getProbability(id) {
      for (let i = 0; i < this.probability.length; i++) {
        const element = this.probability[i]
        if (element.value === id) {
          return element.text
        }
      }
      return ''
    },
    getImpact(id) {
      for (let i = 0; i < this.impact.length; i++) {
        const element = this.impact[i]
        if (element.value === id) {
          return element.text
        }
      }
      return ''
    },
    orderSector() {},
  },
  mounted() {
    this.loadData()
    this.loadRisksBySector()
  },
}
</script>

<style>
.chart {
  height: 300px;
}
</style>
